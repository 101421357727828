<template>
  <div class="container-gift-page">
    <div class="banner-larg-page">
      <div class="filtro-gradiente-img" />
      <div class="container-text-banner">
        <h1>Guía de regalos</h1>
        <p>
          La guía definitiva de regalos está aquí, encuentra detalles únicos
          cargados de emoción y agradecimiento, explora las categorías, filtra
          por precio, color o colecciones.
        </p>
      </div>
      <img
        class="img-banner-large-page d-none d-md-flex"
        src="@/assets/img/banner-regalos.png"
        alt=""
      />
      <img
        class="img-banner-large-page d-flex d-md-none"
        src="@/assets/img/banner-regalos-movil.png"
        alt=""
      />
      <div class="container-cards-gift">
        <div
          v-for="(item, index) in giftFrom"
          id="card-gift-type-id"
          :key="index + 'Header'"
          class="card-gift-type"
        >
          <img src="@/assets/icons/gift.svg" alt="" />
          <div class="ps-3">
            <p class="text-regalos">Regalos</p>
            <h6 class="text-category-gift">{{ item.text }}</h6>
          </div>
        </div>
        <carousel
          class="col-12 carousel-page-gift"
          :dots="false"
          :lazy-load="true"
          :nav="false"
          :responsive="{
            0: { items: 1, nav: false, stagePadding: 30 },
            704: { items: 2, nav: false, stagePadding: 60 },
            1030: { items: 3, nav: false, stagePadding: 80 },
          }"
        >
          <div
            v-for="(item, index) in giftFrom"
            :key="index + 'Header-carousel'"
            class="card-gift-type"
          >
            <img style="width: auto" src="@/assets/icons/gift.svg" alt="" />
            <div class="ps-3">
              <p class="text-regalos">Regalos</p>
              <h6 class="text-category-gift">{{ item.text }}</h6>
            </div>
          </div>
        </carousel>
      </div>
    </div>
    <div class="container-find-perfect-gift col-12 col-md-10 mx-auto">
      <h2 class="title-gift">Encuentra el regalo perfecto</h2>
      <div
        v-for="(item, index2) in cardsGiftLarge"
        :key="index2 + 'large'"
        class="col-12 col-md-3 px-md-2 px-5 mb-4"
      >
        <div class="large-category-gift">
          <img :src="item.img" alt="" />
          <div class="container-large-category">
            <div class="background-container-large-category">
              <div class="text-container-large-gift">
                <p>{{ item.textOpciones }}</p>
                <h5>{{ item.title }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index3) in cardsGiftShort"
        :key="index3 + 'short'"
        class="col-12 col-md-3 px-md-2 px-5 mb-4"
      >
        <div class="short-category-gift">
          <img :src="item.img" alt="" />
          <div class="container-short-category">
            <div class="background-container-short-category">
              <div class="text-container-short-gift">
                <p style="color: white;">{{ item.textOpciones }}</p>
                <h5 style="color: white;">{{ item.title }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <baner-cupo class="my-5" />
    <div class="w-100">
      <carousel-products :items-xfila="4" :title="'Blusas /'" />
      <carousel-products :items-xfila="4" :title="'Accesorios   /'" />
      <carousel-products :items-xfila="4" :title="'Tenis   /'" />
    </div>
  </div>
</template>
<script>
import carousel from 'v-owl-carousel'
import BanerCupo from '@/components/Templates/BanerCupo'
import CarouselProducts from '@/components/Templates/CarouselProducts.vue'
export default {
  components: { BanerCupo, CarouselProducts, carousel },
  data() {
    return {
      giftFrom: [
        { text: 'Para tu mejor amiga' },
        { text: 'Para tus familiares' },
        { text: 'Para tus compañeras' },
        { text: 'Para tu suegra' },
      ],
      cardsGiftLarge: [
        {
          img: '/assets/img/img-large-category-gift-1.png',
          textOpciones: '123 Opciones',
          title: 'Hasta $70.000',
        },
        {
          img: '/assets/img/img-large-category-gift-2.png',
          textOpciones: '203 Opciones',
          title: 'Hasta $100.000',
        },
        {
          img: '/assets/img/img-large-category-gift-3.png',
          textOpciones: '83 Opciones',
          title: 'Hasta $200.000',
        },
        {
          img: '/assets/img/img-large-category-gift-4.png',
          textOpciones: '34 Opciones',
          title: 'Hasta $300.000',
        },
      ],
      cardsGiftShort: [
        {
          img: '/assets/img/img-short-category-gift-1.png',
          textOpciones: '123 Opciones',
          title: 'Hasta $70.000',
        },
        {
          img: '/assets/img/img-short-category-gift-2.png',
          textOpciones: '203 Opciones',
          title: 'Hasta $100.000',
        },
        {
          img: '/assets/img/img-short-category-gift-3.png',
          textOpciones: '83 Opciones',
          title: 'Hasta $200.000',
        },
        {
          img: '/assets/img/img-short-category-gift-4.png',
          textOpciones: '34 Opciones',
          title: 'Hasta $300.000',
        },
      ],
    }
  },
  mounted() {
    // this.$gtag.pageview(this.$route.fullPath)
  },
}
</script>
